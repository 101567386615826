exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-buchung-erfolgreich-jsx": () => import("./../../../src/pages/buchung/erfolgreich.jsx" /* webpackChunkName: "component---src-pages-buchung-erfolgreich-jsx" */),
  "component---src-pages-buchung-index-jsx": () => import("./../../../src/pages/buchung/index.jsx" /* webpackChunkName: "component---src-pages-buchung-index-jsx" */),
  "component---src-pages-digitalisierung-jsx": () => import("./../../../src/pages/digitalisierung.jsx" /* webpackChunkName: "component---src-pages-digitalisierung-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-kurse-jsx": () => import("./../../../src/pages/kurse.jsx" /* webpackChunkName: "component---src-pages-kurse-jsx" */),
  "component---src-pages-referenzen-jsx": () => import("./../../../src/pages/referenzen.jsx" /* webpackChunkName: "component---src-pages-referenzen-jsx" */),
  "component---src-pages-ueber-uns-jsx": () => import("./../../../src/pages/ueber-uns.jsx" /* webpackChunkName: "component---src-pages-ueber-uns-jsx" */),
  "component---src-pages-warenkorb-jsx": () => import("./../../../src/pages/warenkorb.jsx" /* webpackChunkName: "component---src-pages-warenkorb-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-course-type-jsx": () => import("./../../../src/templates/courseType.jsx" /* webpackChunkName: "component---src-templates-course-type-jsx" */),
  "component---src-templates-digitalization-jsx": () => import("./../../../src/templates/digitalization.jsx" /* webpackChunkName: "component---src-templates-digitalization-jsx" */),
  "component---src-templates-legal-text-jsx": () => import("./../../../src/templates/legalText.jsx" /* webpackChunkName: "component---src-templates-legal-text-jsx" */)
}

