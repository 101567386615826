import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { LazyMotion, domAnimation } from 'framer-motion';

import { theme } from './theme';

export const RootProvider = ({ element }) => (
	<ChakraProvider theme={theme}>
		<LazyMotion features={domAnimation}>{element}</LazyMotion>
	</ChakraProvider>
);
